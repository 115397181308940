import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { ValidateFields } from "../validate/ValidateFields";
import ErrorFilter from "../../helper/errorFilter";
import api from "../../helper/api";
import SingleError from "../../helper/SingleError";
import { getServerURL } from "../../helper/envConfig";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
const requireField = ["description"];

const Working = ({ temp, search, searchTemp, setCountWork }) => {

  const serverURL = getServerURL();

  const [editModel, setEditModel] = useState(false);


  const editModelOpenFun = () => {
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);


  const [loading, setLoading] = useState(false)
  const [submitCount, setSubmitCount] = useState(0)
  const [errors, setErrors] = useState({})
  const [mainLoader, setMainLoader] = useState(true);

  const [workingData, setWorkingData] = useState()
  const [editData, setEditData] = useState({})
  const [page, setPage] = useState(1);

  useEffect(() => {
    getWorkingList();
  }, []);

  useEffect(() => {
    getWorkingList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getWorkingList();

    }
    if (searchTemp && !search) {
      getWorkingList();

    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const getWorkingList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}landing-page-manage`,
          { action: "working_get" }
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            if (Boolean(res?.data && Object.keys(res?.data?.data)?.length)) setCountWork(1)
            setWorkingData(res?.data?.data);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setWorkingData([]);
            if (Boolean(page !== 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) {
      setMainLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editData, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    setEditData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };


  const handleSubmit = () => {
    // setTemp(false);
    const updatedValues = { ...editData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.forEach(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors)?.length === 0) {
      setLoading(true);

      const formData = new FormData();
      formData.append("id", editData?._id);
      formData.append("action", "working_update");
      formData.append("description", editData?.description);

      try {
        api
          .postWithToken(
            `${serverURL}landing-page-manage`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              editModelCloseFun();
              getWorkingList()
              setEditData({})
              if (typeof res?.data?.message === "string")
                toast.success(res?.data?.message);
              else toast.success("How it works updated successfully!");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {Boolean(mainLoader) ?
        <Loader /> :
        Boolean(Object.keys(workingData)?.length) ?
          <div className="card p-3 my-3">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="explore-head col-10">
                How it Work?
              </div>
              <button
                className="border-0 bg-transparent"
                onClick={() => { editModelOpenFun(); setEditData(workingData) }}
              >
                <img src="/images/svg/edit.svg" title="Edit" alt="" />
              </button>
            </div>
            <div className="explore-description col-10">
              {workingData?.description ? workingData?.description : "-"}
            </div>

          </div>
          :
          <h4 className="text-center p-5">No Records Founds.</h4>
      }


      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Working</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>

            <Col md={12} id="description">
              <label>Description</label>
              <textarea
                rows={8}
                className="form-control px-3"
                placeholder="Enter description here"
                type="text"
                name="description"
                value={editData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                handleSubmit();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default Working;
