import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "../TablePagination";
import { getImageURL, getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import { ValidateFields } from "../validate/ValidateFields";
import ErrorFilter from "../../helper/errorFilter";
import SingleError from "../../helper/SingleError";
import { toast } from "react-toastify";
import { errorResponse } from "../../helper/error";
import Loader from "../loader/Loader";
const requireField = ["review_image", "name", "description"];

const ReviewManagement = ({ temp, search, searchTemp }) => {

  const serverURL = getServerURL();
  const imageURL = getImageURL();
  const fileInputRef = useRef(null);

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setSelectedImage(null);
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [page, setPage] = useState(1);
  const limit = 10;

  const [reviewData, setReviewData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);
  const [editReviewData, setEditReviewData] = useState({});
  const [deleteReviewData, setDeleteReviewData] = useState({});



  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editReviewData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditReviewData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editReviewData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditReviewData({
        ...editReviewData,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      editReview();
    }
  };

  const getReviewList = useCallback(() => {
    try {
      api
        .postWithToken(
          `${serverURL}landing-review-manage?page=${page}&limit=${limit}&search=${search}`,
          { action: 'review_get' }
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            setReviewData(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
          if (!Boolean(res?.data?.data?.length)) {
            if (Boolean(page !== 1)) {
              setPage(page - 1);
            }
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (e?.response?.status === 404 || e?.response?.statusText === 'Not Found') {
            setReviewData([]);
            if (Boolean(page !== 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) {
    }
  }, [page, limit, search, serverURL]);

  useEffect(() => {
    getReviewList();
  }, [getReviewList]);

  useEffect(() => {
    getReviewList();
  }, [page, getReviewList]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getReviewList();
    }
    if (searchTemp && !search) {
      getReviewList();
    }
  }, [temp, search, searchTemp, getReviewList]);
  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const editReview = () => {
    const updatedValues = { ...editReviewData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.forEach(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", editReviewData?._id);
      formData.append("action", "review_update");
      formData.append("name", editReviewData?.name);
      formData.append("description", editReviewData?.description);
      formData.append("review_image", editReviewData?.review_image);
      try {
        api
          .postWithToken(`${serverURL}landing-review-manage`, formData)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              getReviewList();
              editModelCloseFun();
              setSelectedImage("");
              if (res?.data?.success === true) {
                if (typeof res?.data?.message === "string")
                  toast.success(res?.data?.message);
                else toast?.success("Review Updated successfully");
              }
            }
          })
          .catch((e) => {
            // console.log(e);
            setLoading(false);
            errorResponse(e);
            if (
              e?.response?.status === 404 ||
              e?.response?.statusText === "Not Found"
            ) {
              setReviewData([]);
              if (Boolean(page !== 1)) {
                setPage(page - 1);
              }
            }
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const deleteReview = () => {
    try {
      api
        .postWithToken(`${serverURL}landing-review-manage`, {
          action: "review_delete",
          id: deleteReviewData,
        })
        .then(async (res) => {
          setDeleteReviewData("");
          getReviewList();
          if (reviewData?.length === 1) {
            setReviewData([]);
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Review deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  return (
    <>
      <Col className="ctmTable mt-3">
        <Table bordered responsive className="text-center mb-0">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th className="w-15">Image</th>
              <th className="w-25">Name</th>
              <th className="w-75">Descriptions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {Boolean(mainLoader) ? (
              <Loader />
            ) : Boolean(reviewData?.length) ? (
              reviewData?.map((item, i) => (
                <tr key={i + "contentTable"}>
                  <td>{i + 1}</td>
                  <td>
                    <img
                      src={imageURL + item?.review_image}
                      className="review-tbl-img px-2" alt=""
                    />
                  </td>
                  <td className="fullTextShow text-center px-2">
                    {item?.name}
                  </td>
                  <td>
                    <div className="w-100 fullTextShow text-center px-2">
                      {item?.description}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center gap-1">
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          viewModelOpenFun();
                          setEditReviewData(item);
                        }}
                      >
                        <img src="/images/svg/show.svg" title="View" alt="" />
                      </button>
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          editModelOpenFun();
                          setEditReviewData(item);
                        }}
                      >
                        <img src="/images/svg/edit.svg" title="Edit" alt="" />
                      </button>
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          deleteModelOpenFun(item?._id);
                          setDeleteReviewData(item?._id);
                        }}
                      >
                        <img src="/images/svg/delete.svg" title="Delete" alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="p-5">
                  No Records Founds.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center mt-4 mb-4">
          {Boolean(reviewData?.length) && paginationData?.totalPages > 1 && (
            <TablePagination
              currentPage={paginationData?.currentPage}
              totalPages={paginationData?.totalPages}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </div>
      </Col>

      {/* View modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Review Management Details
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0cmt-3">
          <Row>
            <div className="position-relative">
              <div className="d-flex justify-content-center m-auto edit-user">
                <img
                  src={
                    editReviewData?.review_image &&
                    imageURL + editReviewData?.review_image
                  }
                  className="cursor-pointer" alt=""
                />
              </div>
            </div>
          </Row>
          <Row className="px-5 pb-3">
            <div className="d-flex justify-content-center">
              <div>
                <tr>
                  <td className="userDetailsLabel">Name:</td>
                  &nbsp;
                  <td className="userDetailsText">
                    {editReviewData?.name ? editReviewData?.name : "-"}
                  </td>
                </tr>

                <tr>
                  <td className="userDetailsLabel">Description:</td>
                  &nbsp;
                  <td className="userDetailsText fullTextShow">
                    {editReviewData?.description
                      ? editReviewData?.description
                      : "-"}
                  </td>
                </tr>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Edit Review Management
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="review_image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    editReviewData?.review_image
                      ? typeof editReviewData?.review_image === "string"
                        ? imageURL + editReviewData?.review_image
                        : editReviewData?.review_image?.type
                          ? URL.createObjectURL(editReviewData?.review_image)
                          : "/images/png/duser.png"
                      : selectedImage
                        ? selectedImage
                        : "/images/png/duser.png"
                  } alt=""
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : editReviewData?.review_image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                />
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="review_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.review_image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.review_image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter review title here"
                type="text"
                name="name"
                value={editReviewData?.name || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.name} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={5}
                className="form-control px-3"
                placeholder="Enter review description here"
                type="text"
                name="description"
                value={editReviewData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                editReview();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Review</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28  fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn px-5 border-0"
              onClick={() => deleteReview()}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReviewManagement;
