import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "../TablePagination";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import Loader from "../loader/Loader";

const ContactManagement = ({ temp, search, searchTemp }) => {
  const serverURL = getServerURL();

  const [viewModel, setViewModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const [contactData, setContactData] = useState([]);
  const [editData, setEditData] = useState({});
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  const limit = 10;
  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    getContactList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getContactList();
    }
    if (searchTemp && !search) {
      getContactList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const getContactList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}landing-page-manage?page=${page}&limit=${limit}&search=${search}`,
          { action: "contacts_get" }
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            setContactData(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
          if (!Boolean(res?.data?.data?.length)) {
            if (Boolean(page !== 1)) {
              setPage(page - 1);
            }
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setContactData([]);
            if (Boolean(page !== 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) { }
  };

  return (
    <>
      <Col className="ctmTable mt-3">
        <Table bordered responsive className="text-center mb-0">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Contact No.</th>
              {/* <th className="w-25">Message</th>
              <th className="w-15"> Instagram Profile:</th>
              <th className="w-15">Facebook Profile:</th>
              <th className="w-15">Website:</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {Boolean(mainLoader) ? (
              <Loader />
            ) : Boolean(contactData?.length) ? (
              contactData?.map((item, i) => (
                <tr key={i + "contentTable"}>
                  <td>{i + 1}</td>
                  <td>{item?.first_name || "-"}</td>
                  <td>{item?.last_name || "-"}</td>
                  <td>{item?.email || "-"}</td>
                  <td>{item?.contact_no ? "+" + item?.contact_no : "-"}</td>
                  {/* <td className="ctm-width text-center px-2">
                    {item?.message || "-"}
                  </td>
                  <td className="ctm-width text-center px-2 ">
                    <div>{item?.instagram_url || "-"}</div>
                  </td>
                  <td className="ctm-width text-center px-2 ">
                    <div>{item?.facebook_url || "-"}</div>
                  </td>
                  <td className="ctm-width text-center px-2 ">
                    <div>{item?.website_url || "-"}</div>
                  </td> */}
                  <td>
                    <div className="d-flex justify-content-center align-items-center gap-1">
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          viewModelOpenFun();
                          setEditData(item);
                        }}
                      >
                        <img src="/images/svg/show.svg" title="View" alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="p-5">
                  No Records Founds.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          {Boolean(contactData?.length) && paginationData?.totalPages > 1 && (
            <TablePagination
              currentPage={paginationData?.currentPage}
              totalPages={paginationData?.totalPages}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </div>
      </Col>

      {/* View modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Contact Management Details
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="s-img" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 cmt-3">
          <Row className="justify-content-center">
            <Col className="col-11 px-0">
              <div className="d-flex justify-content-center">
                <div className="my-4">
                  <tr>
                    <td className="userDetailsLabel">First Name:</td>
                    &nbsp;
                    <td className="userDetailsText">
                      {editData?.first_name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Last Name:</td>
                    &nbsp;
                    <td className="userDetailsText">
                      {editData?.last_name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Email:</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow ctm-width ctm-link">
                      <div>{editData?.email ? (
                        <a href={`mailto:${editData?.email}`} target="_blank" rel="noopener noreferrer">
                          {editData.email}
                        </a>
                      ) : (
                        "-"
                      )}</div>
                    </td>
                  </tr>

                  <tr>
                    <td className="userDetailsLabel">Contact No:</td>
                    &nbsp;
                    <td className="userDetailsText">
                      {editData?.contact_no ? "+" + editData?.contact_no : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Message:</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">
                      {editData?.message || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Instagram Profile:</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow ctm-width ctm-link">
                      <div>{editData?.instagram_url ? (
                        <a href={editData.instagram_url} target="_blank" rel="noopener noreferrer">
                          {editData.instagram_url}
                        </a>
                      ) : (
                        "-"
                      )}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Facebook Profile:</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow ctm-width ctm-link">
                      <div>{editData?.facebook_url ? (
                        <a href={editData.facebook_url} target="_blank" rel="noopener noreferrer">
                          {editData.facebook_url}
                        </a>
                      ) : (
                        "-"
                      )}</div>
                    </td>
                    {/* <td className="userDetailsText fullTextShow ctm-width">
                      <div>{editData?.facebook_url || ""}</div>
                    </td> */}
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Website:</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow ctm-width ctm-link">
                      <div>{editData?.website_url ? (
                        <a href={editData.website_url} target="_blank" rel="noopener noreferrer">
                          {editData.website_url}
                        </a>
                      ) : (
                        "-"
                      )}</div>
                    </td>
                    {/* <td className="userDetailsText fullTextShow ctm-width">
                      <div>{editData?.website_url || "-"}</div>
                    </td> */}
                  </tr>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactManagement;
