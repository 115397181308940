import Login from "../auth/Login";
import Dashboard from "../pages/Dashboard";
import { UserManagement } from "../pages/UserManagement";
import TrackingManagement from "../pages/TrackingManagement";
import ContentManagement from "../pages/ContentManagement";
import Community from "../pages/Community";
import Revenue from "../pages/Revenue";
import Analysis from "../pages/Analysis";
import Week from "../pages/Week";
import StressLevelManagement from "../pages/StressLevelManagement";
import FoodsProteins from "../pages/FoodsProteins";
import MenstrualCycleTracker from "../pages/MenstrualCycleTracker";
import NutrientsRichFoods from "../pages/NutrientsRichFoods";
import BodyExercise from "../pages/BodyExercise";
import Profile from "../pages/Profile";
import WaterCycleTracker from "../pages/WaterCycleTracker";
import VirtualFitness from "../pages/VirtualFitness";
import MyRegimen from "../pages/MyRegimen";
import ShowGraph from "../pages/ShowGraph";
import TrackingManagement2 from "../pages/Tracking2";
import { SubscriptionManagement } from "../pages/SubscriptionManagement";
import { QuoteManagement } from "../pages/QuoteManagement";
import Verification from "../auth/Verification";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import { Navigate } from "react-router-dom";
import ProtectLoginRoute from "./ProtectLoginRoute";
import PushUp from "../pages/PushUp";
import Glutes from "../pages/Glutes";
import Legs from "../pages/Legs";
import Shoulders from "../pages/Shoulders";
import FoodsCalcium from "../pages/FoodsCalcium";
import FoodsIron from "../pages/FoodsIron";
import FoodsMagnesium from "../pages/FoodsMagnesium";
import FoodsVitaminD from "../pages/FoodsVitaminD";
import { VideoLink } from "../pages/VideoLink";
import ChangePassword from "../auth/ChangePassword";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import LandingManagement from "../pages/LandingManagement";
import { CourseManagement } from "../pages/CourseManagement";
import UIPagesManagement from "../pages/UIPagesManagement";
import Settings from "../pages/Settings";
import Testimonial from "../pages/Testimonial";

const routes =
  [
    {
      path: "/login",
      element: (
        <ProtectLoginRoute>
          <Login />
        </ProtectLoginRoute>
      ),
    },
    {
      path: "/change-password",
      element: (
        <PrivateRoute>
          <ChangePassword />
        </PrivateRoute>
      ),
    },
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
    },
    {
      path: "/user-management",
      element: (
        <PrivateRoute>
          <UserManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/course-management",
      element: (
        <PrivateRoute>
          <CourseManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/week-management",
      element: (
        <PrivateRoute>
          <Week />
        </PrivateRoute>
      ),
    },
    {
      path: "/tracking-management",
      element: (
        <PrivateRoute>
          <TrackingManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/myregimen",
      element: (
        <PrivateRoute>
          <MyRegimen />
        </PrivateRoute>
      ),
    },
    {
      path: "/content-management",
      element: (
        <PrivateRoute>
          <ContentManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/community",
      element: (
        <PrivateRoute>
          <Community />
        </PrivateRoute>
      ),
    },
    {
      path: "/revenue",
      element: (
        <PrivateRoute>
          <Revenue />
        </PrivateRoute>
      ),
    },
    {
      path: "/analysis",
      element: (
        <PrivateRoute>
          <Analysis />
        </PrivateRoute>
      ),
    },
    {
      path: "/quote-management",
      element: (
        <PrivateRoute>
          <QuoteManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/subscription-management",
      element: (
        <PrivateRoute>
          <SubscriptionManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <ProtectLoginRoute>
          <ForgotPassword />
        </ProtectLoginRoute>
      ),
    },
    {
      path: "/verification",
      element: (
        <ProtectedRoute data={"resetpass"}>
          <Verification />
        </ProtectedRoute>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <ProtectedRoute data="verify">
          <ResetPassword />
        </ProtectedRoute>
      ),
    },
    {
      path: "/stresslevel",
      element: (
        <PrivateRoute>
          <StressLevelManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/menstrualcycletracker",
      element: (
        <PrivateRoute>
          <MenstrualCycleTracker />
        </PrivateRoute>
      ),
    },
    {
      path: "/watercycleracker",
      element: (
        <PrivateRoute>
          <WaterCycleTracker />
        </PrivateRoute>
      ),
    },
    {
      path: "/foodsproteins",
      element: (
        <PrivateRoute>
          <FoodsProteins />
        </PrivateRoute>
      ),
    },
    {
      path: "/nutrientsfichfoods",
      element: (
        <PrivateRoute>
          <NutrientsRichFoods />
        </PrivateRoute>
      ),
    },
    {
      path: "/bodyexercise",
      element: (
        <PrivateRoute>
          <BodyExercise />
        </PrivateRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      ),
    },
    {
      path: "/virtualfitness",
      element: (
        <PrivateRoute>
          <VirtualFitness />
        </PrivateRoute>
      ),
    },
    {
      path: "/showgraph",
      element: (
        <PrivateRoute>
          <ShowGraph />
        </PrivateRoute>
      ),
    },
    {
      path: "/tracking2",
      element: (
        <PrivateRoute>
          <TrackingManagement2 />
        </PrivateRoute>
      ),
    },
    {
      path: "/push-up",
      element: (
        <PrivateRoute>
          <PushUp />
        </PrivateRoute>
      ),
    },
    {
      path: "/glutes",
      element: (
        <PrivateRoute>
          <Glutes />
        </PrivateRoute>
      ),
    },
    {
      path: "/legs",
      element: (
        <PrivateRoute>
          <Legs />
        </PrivateRoute>
      ),
    },
    {
      path: "/shoulders",
      element: (
        <PrivateRoute>
          <Shoulders />
        </PrivateRoute>
      ),
    },
    {
      path: "/foodscalcium",
      element: (
        <PrivateRoute>
          <FoodsCalcium />
        </PrivateRoute>
      ),
    },
    {
      path: "/foodsmagnesium",
      element: (
        <PrivateRoute>
          <FoodsMagnesium />
        </PrivateRoute>
      ),
    },
    {
      path: "/foodsiron",
      element: (
        <PrivateRoute>
          <FoodsIron />
        </PrivateRoute>
      ),
    },
    {
      path: "/foodsvitamind",
      element: (
        <PrivateRoute>
          <FoodsVitaminD />
        </PrivateRoute>
      ),
    },
    {
      path: "/video-management",
      element: (
        <PrivateRoute>
          <VideoLink />
        </PrivateRoute>
      ),
    },
    // {
    //   path: "/home-management",
    //   element: (
    //     <PrivateRoute>
    //       <HomeManagement />
    //     </PrivateRoute>
    //   ),
    // },
    {
      path: "/ui-management",
      element: (
        <PrivateRoute>
          <UIPagesManagement />
        </PrivateRoute>
      ),
    },
    {
      path: "/landing-management",
      element: (
        <PrivateRoute>
          <LandingManagement />
        </PrivateRoute>
      ),
    }, {
      path: "/testimonial",
      element: (
        <PrivateRoute>
          <Testimonial />
        </PrivateRoute>
      ),
    },
    {
      path: "/Settings",
      element: (
        <PrivateRoute>
          <Settings />
        </PrivateRoute>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ];
export default routes;
