import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import api from "../helper/api";
import { getServerURL, getImageURL } from "../helper/envConfig";
import { ValidateFields } from "./validate/ValidateFields"
import ErrorFilter from "../helper/errorFilter";
import SingleError from "../helper/SingleError";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import { format } from 'date-fns';
import Loader from "./loader/Loader";

const TestimonialList = ({ temp, search, searchTemp }) => {
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [testimonialList, setTestimonialList] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);
  const [paginationData, setPaginationData] = useState({});
  const [isImageValid, setIsImageValid] = useState(true);

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const serverURL = getServerURL();
  // const imagepath = "http://157.245.107.181:3146/";
  const imagepath = getImageURL();

  const [selectedImage, setSelectedImage] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [state, setState] = useState({
    title: "",
    description: "",
    test_image: null, // Changed to handle file input
  });
  const imageUrl = getImageURL();
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState({});
  const requiredFields = ["title", "description", "test_image"];


  const viewModelOpenFun = (item) => {
    setSelectedItem(item);
    setViewModel(true);
  };

  const viewModelCloseFun = () => setViewModel(false);
  const editModelOpenFun = (item) => {
    setSelectedItem(item);
    setId(item._id);

    const updatedState = {
      title: item.title,
      description: item.description || "",
      test_image: item.test_image || ""
    };
    setSelectedImage(item.test_image ? imageUrl + item.test_image : "");
    setState(updatedState);
    setEditModel(true);
  };
  const [loading, setLoading] = useState(false);
  const editModelCloseFun = () => {
    setEditModel(false)
    setErrors({});
    setSelectedImage(state?.test_image ? imageUrl + state?.test_image : "");
    setIsImageValid(true)
  }
  const deleteModelOpenFun = (item) => {
    setSelectedItem(item);
    setDeleteModel(true);
  };


  const deleteModelCloseFun = () => setDeleteModel(false);
  const [id, setId] = useState(null);

  const getTestimonial = useCallback(async () => {
    try {
      const response = await api.getWithToken(`${serverURL}testimonial/list?page=${page}&limit=${limit}&search=${search}`);
      setMainLoader(false);

      if (response?.data?.success) {
        setTestimonialList(response.data.data || []);
        setPaginationData(response.data.pagination || {});
        if (response.data.data.length > 0) {
          setId(response.data.data[0]._id);
        }
      } else {
        setTestimonialList([]);
      }

    } catch (error) {
      console.error("Error fetching products:", error.response ? error.response.data : error.message);
    }
  }, [page, limit, search, serverURL]); // Dependencies for getTestimonial

  useEffect(() => {
    getTestimonial();
  }, [getTestimonial]); // Add getTestimonial to dependency array


  useEffect(() => {
    getTestimonial();
  }, [page, getTestimonial]); // Add getTestimonial here

  useEffect(() => {
    if (temp || Boolean(search)) {
      getTestimonial();
    }
    if (searchTemp && !search) {
      getTestimonial();
    }
  }, [temp, search, searchTemp, getTestimonial]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const handleDelete = async (itemId) => {
    try {
      const response = await api.putWithToken(`${serverURL}testimonial/delete/${itemId}`);

      deleteModelCloseFun();
      if (response?.data?.success) {

        getTestimonial();
        if (testimonialList.length === 1 && paginationData.currentPage > 1) {
          setPage((prePage) => prePage - 1);
        } else {
          setTestimonialList();
        }
        const successMessage = typeof response?.data?.message === "string"
          ? response?.data?.message
          : "Testimonial deleted successfully";
        toast.success(successMessage);
        deleteModelCloseFun(); // Close the delete modal
      } else {
        toast.error(response?.data?.message || "Failed to delete the Testimonial");
      }


    } catch (error) {
      console.error("Error deleting item:", error.response || error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (submitCount > 0) validateFields({ ...state, [name]: value });
  };
  const validateFields = (updatedValues) => {
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requiredFields);

    if (updatedValues?.description?.trim().length === 0) {
      validationErrors.description = "Description field is required.";
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  const fileInputRef = useRef(null);
  const handleImageClick = () => fileInputRef.current.click();


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();
      reader.onload = (event) => {
        img.src = event.target.result
      }
      img.onload = () => {
        if (img.width === 1324 && img.height === 720) {
          setSelectedImage(img.src);
          setState((prev) => ({ ...prev, test_image: file }));
          setErrors((prevErrors) => ({ ...prevErrors, test_image: "" }));
          setIsImageValid(true);
        }
        else {
          setErrors((prevErrors) => ({ ...prevErrors, test_image: "Please upload an image with dimensions 1324×720." }));
          setIsImageValid(false);
        }

      }
      reader.onloadend = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);

    }
  };

  const handleFormSubmit = async () => {
    setSubmitCount((count) => count + 1);

    // Trim whitespace from title and description
    const updatedState = {
      ...state,
      description: state.description.trim(),
    };

    const validationErrors = validateFields(updatedState);

    if (Object.keys(validationErrors).length === 0) {
      await handleSubmit();  // Ensure handleSubmit is awaited to handle asynchronous submission
    } else {
      const firstErrorKey = Object.keys(validationErrors)[0];
      document.getElementById(firstErrorKey)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Create FormData with trimmed values
    const formData = new FormData();
    Object.keys(state).forEach((key) => {
      // Trim title and description before appending to FormData
      if (key === 'description') {
        formData.append(key, state[key].trim());
      } else {
        formData.append(key, state[key]);
      }
    });

    try {
      const response = await api.putWithToken(`${serverURL}testimonial/edit/${id}`, formData);
      if (response?.data?.success) {
        handleSuccess(response);
        getTestimonial();
      }
    } catch (error) {
      errorResponse(error);  // Assuming errorResponse is your error handling function
    } finally {
      setLoading(false);
    }
  };
  const handleSuccess = (response) => {
    editModelCloseFun();
    setRefreshData(!refreshData);

    const successMessage = typeof response?.data?.message === "string"
      ? response?.data?.message
      : "Testimonial updated successfully";
    toast.success(successMessage);
    console.log(response?.data?.message);

  };

  return (
    <>
      {mainLoader ? (
        <div className="loading-indicator">
          <Loader />
        </div>
      ) : (
        <Col className="ctmTable">
          <Table bordered responsive className="text-center">
            <thead className="tableHeader">
              <tr>
                <th className="number-width border">No</th>
                <th>Image</th>
                <th>Title</th>
                <th className="w-25">Descriptions</th>
                <th>Created Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {testimonialList?.length > 0 ? (
                testimonialList?.map((item, index) => (
                  <tr key={item?._id}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>{item.test_image ? <img src={`${imageUrl}${item?.test_image}`} alt={item?.title} style={{ width: '100px', height: 'auto' }} /> : "-"}

                    </td>
                    <td>{item?.title}</td>
                    <td>
                      <div className="wordBreak1 text-center align-middle">
                        {item?.description}
                      </div>
                    </td>
                    <td>{format(new Date(item?.createdAt), 'dd-MM-yyyy')}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <button className="border-0 bg-transparent" onClick={() => viewModelOpenFun(item)}>
                          <img src="/images/svg/show.svg" title="View" alt="" />
                        </button>
                        <button className="border-0 bg-transparent" onClick={() => editModelOpenFun(item)}>
                          <img src="/images/svg/edit.svg" title="Edit" alt="" />
                        </button>
                        <button className="border-0 bg-transparent" onClick={() => deleteModelOpenFun(item)}>
                          <img src="/images/svg/delete.svg" title="Delete" alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-md-flex justify-content-end align-items-center">
            {Boolean(testimonialList?.length) && paginationData?.totalPages > 1 && (
              <TablePagination
                currentPage={paginationData?.currentPage}
                totalPages={paginationData?.totalPages}
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
              />
            )}
          </div>
        </Col>
      )}

      {/* View item modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">View Testimonial</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          {selectedItem && (
            <Row>
              <div className="d-flex justify-content-center">
                <div className="w-100">
                  <Row className="w-100">
                    <div className="position-relative my-4">
                      <div className="d-flex justify-content-center align-items-center m-auto ">
                        <img
                          src={`${imagepath}${selectedItem.test_image}`}
                          className="editprofileImage cursor-pointer" alt=""
                        />
                      </div>
                    </div>
                  </Row>
                  <div className="mb-3">
                    <tr>
                      <td className="userDetailsLabel">Title:</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.title}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel">Description:</td>
                      &nbsp;
                      <td className="userDetailsText max-width-100">{selectedItem.description}</td>
                    </tr>

                    <tr>
                      <td className="userDetailsLabel">Date:</td>
                      &nbsp;
                      <td className="userDetailsText">{new Date(selectedItem.createdAt).toLocaleDateString()}</td>
                    </tr>
                  </div>
                </div>
              </div>
            </Row>
          )}
        </Modal.Body>
      </Modal>



      <Modal size="lg" show={editModel}
        onHide={editModelCloseFun} centered className="modal-main info-modal">
        <Modal.Header className="d-block position-relative border-0 pt-4 px-4" closeButton>
          <Modal.Title className="text-center">Edit Testimonial</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="line" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={selectedImage || (state?.test_image && imageUrl + state?.test_image) || "/images/png/duser.png"}
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage || state?.test_image ? "editprofileImage" : "profileImageSize"}`}
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="test_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt="Upload"
              />
            </div>
            {errors?.test_image && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors.test_image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="title">
                <Form.Label>Title <span className="star">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter testimonial title here"
                  name="title"
                  value={state?.title || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.title} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="description">
                <Form.Label>Description <span className="star">*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter testimonial description here"
                  name="description"
                  value={state?.description || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.description} />
              </Form.Group>
            </Col>

          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${loading ? "btnDisable" : ""} ${!isImageValid ? "btnGrey" : ""}`}
              disabled={loading || !isImageValid}
              onClick={handleFormSubmit}
            >
              {loading ? "Loading..." : "Edit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}

      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Testimonial</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button className="modalDeleteBtn border-0" onClick={() => handleDelete(selectedItem?._id)}>Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestimonialList;
