// src/pages/Testimonial.js
import React, { useState, useRef, useEffect } from "react";
import Main from "../hoc/Main";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { getImageURL, getServerURL } from "../helper/envConfig";
import SingleError from "../helper/SingleError";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import TestimonialList from "../components/TestimonialList";
import Loader from "../components/loader/Loader";

const Testimonial = () => {
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [mainLoader, setMainLoader] = useState(true);
  const [setTestimonialList] = useState([]);
  const fileInputRef = useRef(null);
  const imageUrl = getImageURL();
  const serverURL = getServerURL();
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const requiredFields = ["test_image", "title", "description"];

  const openModal = () => {
    resetForm();
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const resetForm = () => {
    setSubmitCount(0);
    setErrors({});
    setState({ title: "", description: "" });
    setSelectedImage(null);
  };

  const handleImageClick = () => fileInputRef.current.click();

  const handleFileChange = (e, error) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      const img = new Image()
      reader.onloadend = () => {
        img.onload = () => {
          if (img.width === 1324 && img.height === 720) {
            setSelectedImage(reader.result)
            updateState(e.target.name, file)
            setErrors((prevErrors) => ({ ...prevErrors, test_image: "" }));
          }
          else {
            setErrors((prevErrors) => ({ ...prevErrors, test_image: "Please upload an image with dimensions 1324×720." }));
          }
        }
        img.src = reader.result
      };
      reader.readAsDataURL(file);
    }
  };


  const updateState = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
    if (submitCount > 0) validateFields({ ...state, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState(name, value);
  };

  const validateFields = (updatedValues) => {
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requiredFields);

    if (updatedValues?.description?.trim().length === 0) {
      validationErrors.description = "Description field is required.";
    }

    setErrors(validationErrors);
    return validationErrors;
  };
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setMainLoader(true);
      try {
        const response = await api.getWithToken(`${serverURL}testimonial/list`);
        if (response?.data?.success) {
          setTestimonialList(response.data.data || []);
        } else {
          setTestimonialList([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error.response ? error.response.data : error.message);
      } finally {
        setLoading(false);
        setMainLoader(false);
      }
    };

    fetchProducts();
  }, [refreshData, serverURL, setTestimonialList]);

  const handleFormSubmit = () => {

    setSubmitCount(1);
    const validationErrors = validateFields(state);

    if (Object.keys(validationErrors).length === 0) {
      submitRegimen();


    } else {
      const firstErrorKey = Object.keys(validationErrors)[0];
      document.getElementById(firstErrorKey)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitRegimen = async () => {
    setTemp(false);
    const formData = new FormData();
    Object.keys(state).forEach((key) => {
      formData.append(key, state[key]);
    });
    try {
      const response = await api.postWithToken(`${serverURL}testimonial/create`, formData);
      if (response?.data?.success) {
        handleSuccess(response);
        setTemp(true)
      }
    } catch (error) {
      errorResponse(error);
    }
  };

  const handleSuccess = (response) => {
    closeModal();
    setRefreshData(!refreshData);
    resetForm();
    const successMessage = typeof response?.data?.message === "string"
      ? response?.data.message
      : "Testimonial added successfully";
    toast.success(successMessage);
  };

  const disableAutocomplete = (e) => {
    e.target.setAttribute("autocomplete", "off");
  };


  return (
    <>

      {mainLoader && (
        <div className="loading-indicator">
          <Loader />
        </div>
      )}
      <Main name="Testimonial">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Testimonial Management</h1>
          </Col>
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3">
              <div className="form-control d-flex align-items-center px-3">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="form-control border-0 shadow-none pl-black"
                  placeholder="Enter search text here"
                  type="text"
                  name="search"
                  autoComplete="off"
                  onFocus={disableAutocomplete}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setSearchTemp(true);
                  }}
                />

              </div>
              <div onClick={openModal} className="cursor-pointer">
                <img
                  src="/images/svg/add.svg"
                  className="add-btn"
                  title="Add"
                  alt=""
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-men" role="tabpanel">
            <TestimonialList refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp} />
          </div>

        </div>
      </Main>
      <Modal size="lg" show={isModalOpen} onHide={closeModal} centered className="modal-main info-modal">
        <Modal.Header className="d-block position-relative border-0 pt-4 px-4" closeButton>
          <Modal.Title className="text-center">Add Testimonial</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="line" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={selectedImage || (state?.test_image && imageUrl + state?.test_image) || "/images/png/duser.png"}
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage || state?.test_image ? "editprofileImage" : "profileImageSize"}`}
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="test_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt="Upload"
              />
            </div>
            {errors?.test_image && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors.test_image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="title">
                <Form.Label>Title <span className="star">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter testimonial title here"
                  name="title"
                  value={state?.title || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.title} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="description">
                <Form.Label>Description <span className="star">*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter testimonial description here"
                  name="description"
                  value={state?.description || ""}
                  onChange={handleChange}
                />
                <SingleError error={errors?.description} />
              </Form.Group>
            </Col>

          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${loading ? "btnDisable" : ""}`}
              disabled={loading}
              onClick={handleFormSubmit}
            >
              {loading ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Testimonial;
