import React from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Main from "../hoc/Main";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Analysis = () => {
  const labels = [
    "11.12",
    "12.12",
    "13.12",
    "14.12",
    "15.12",
    "16.12",
    "17.12",
    "18.12",
    "19.12",
    "20.12",
  ];
  const staticData = [200, 500, 400, 200, 700, 500, 300, 600, 300, 1000];

  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: staticData,
        backgroundColor: "#4F74D5",
        borderRadius: 10,
      },
    ],
  };

  const data1 = {
    labels,
    datasets: [
      {
        data: staticData,
        backgroundColor: "#D272C4",
        borderRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Set to false to hide the legend
        position: "top",
      },
      title: {
        display: false,
        text: "Total User",
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: false,
          text: "Date",
        },
        ticks: {
          color: "#303030", // Color of the x-axis labels
          font: {
            size: 17.057, // Font size of the x-axis labels
            weight: 600, // Font weight of the x-axis labels
          },
          // maxRotation: 40.677, // Rotate x-axis label text by 40 degrees
          minRotation: 40.677,
        },
      },
      y: {
        beginAtZero: true,
        stepSize: 5,
        border: {
          dash: [10, 10],
          color: "#ffff",
        },
        grid: {
          display: false,
        },
        title: {
          display: false,
          text: "Value",
        },

        ticks: {
          color: "#303030", // Color of the x-axis labels
          font: {
            size: 17.057, // Font size of the x-axis labels
            weight: 600,
          },
          callback: function (value, index, values) {
            return index === 0 ? value : "";
          },
        },
      },
    },
    barPercentage: 0.6, // Adjust this value to set the width of the bars
    categoryPercentage: 0.7,
  };

  const chartContainerStyle = {
    // width: "650px",
    height: "350px",
  };

  return (
    <>
      <Main name="Analysis">
        <div className="row mt-4">
          <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
            <div className="pb-1">
              <h1 className="title mb-0">User Graph</h1>
            </div>
            <div className="card p-3">
              <div>
                <span className="graphText1">Total User</span>
                <p className="graphText2">999</p>
              </div>
              <div style={chartContainerStyle}>
                <Bar options={options} data={data} />
              </div>

              <button className="w-100 p-3 border-0 downloadBtn mt-4">
                <img src="/images/svg/download.svg" className="pr-2" alt="" />
                Download
              </button>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
            <div className="pb-1">
              <h1 className="title">Subscription Graph</h1>
            </div>
            <div className="card p-3">
              <div>
                <span className="graphText1">Total Subscription</span>
                <p className="graphText2">0000000/-</p>
              </div>
              <div style={chartContainerStyle}>
                <Bar options={options} data={data1} />
              </div>

              <button className="w-100 p-3 border-0 downloadBtn mt-4">
                <img src="/images/svg/download.svg" className="pr-2" alt="" />
                Download
              </button>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
};

export default Analysis;
