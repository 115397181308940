import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { ValidateFields } from "../validate/ValidateFields";
import ErrorFilter from "../../helper/errorFilter";
import api from "../../helper/api";
import SingleError from "../../helper/SingleError";
import { getServerURL } from "../../helper/envConfig";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import { format } from 'date-fns';
import TablePagination from "../TablePagination";
const requireField = ["title", "descriptions", "contact", "email"];

const HelpAndSupport = ({ temp, search, searchTemp }) => {
  const serverURL = getServerURL();

  const [editModel, setEditModel] = useState(false);

  const editModelCloseFun = () => setEditModel(false);

  const [loading, setLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);

  const [helpSupportData, setHelpSupportData] = useState({});
  const [editData, setEditData] = useState({});
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [paginationData, setPaginationData] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    getHelpSupportData();

  }, [page, limit, refreshData, temp, search, searchTemp]);

  const getHelpSupportData = async () => {
    setMainLoader(true);
    try {
      const response = await api.getWithToken(
        `${serverURL}help-support?page=${page}&limit=${limit}&search=${search}`
      );
      if (response?.data?.success) {
        setHelpSupportData(response.data.data || []);
        setPaginationData({
          totalPages: response.data.totalPages,
          currentPage: response.data.page,


        });


      } else {
        setHelpSupportData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
    } finally {
      setMainLoader(false);
    }
  };
  useEffect(() => {
    getHelpSupportData();

  }, [page, limit, refreshData, temp, search, searchTemp]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }

    setEditData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setSubmitCount(1);
    const updatedValues = { ...editData };
    let validationErrors = {};

    if (!updatedValues?.email) {
      validationErrors.email = "Email field is required.";
    } else if (!/\S+@\S+\.\S+/.test(updatedValues?.email)) {
      validationErrors.email =
        "Invalid email format. Please provide a valid email address.";
    }

    if (!updatedValues?.title) {
      validationErrors.title = "Title field is required.";
    } else if (updatedValues?.title?.trim()?.length === 0) {
      validationErrors.title =
        "Title cannot be empty or contain only spaces.";
    } else if (updatedValues?.title?.length < 2) {
      validationErrors.title = "Title should be minimum 2 characters.";
    }

    // Validate description field
    if (!updatedValues?.description) {
      validationErrors.description = "Description field is required.";
    } else if (updatedValues?.description?.trim()?.length === 0) {
      validationErrors.description =
        "Description cannot be empty or contain only spaces.";
    } else if (updatedValues?.description?.trim()?.length < 2) {
      validationErrors.description =
        "Description should be minimum 2 characters.";
    }

    setErrors(validationErrors);

    if (editData?.contact) {
      if (!/^\+?\d+$/.test(editData?.contact)) {
        setErrors((prevValues) => ({
          ...prevValues,
          contact: "Please valid contact number",
        }));
      }
    }

    if (Object.keys(validationErrors)?.length) {
      // eslint-disable-next-line array-callback-return
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0)
          document
            .getElementById(key)
            ?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (
      Object.keys(validationErrors)?.length === 0 &&
      Object.keys(errors)?.length === 0
    ) {
      setLoading(true);

      try {
        api.postWithToken(`${serverURL}help-support`, {
          ...editData,
          action: "help-support-create",
        })
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              editModelCloseFun();
              getHelpSupportData();
              setEditData({});
              if (typeof res?.data?.message === "string") {
                toast.success(res?.data?.message);
              } else {
                toast.success(
                  "Help & support detail updated successfully!"
                );
              }
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };
  const [viewModel, setViewModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const viewModelOpenFun = (item) => {
    setSelectedItem(item);
    setViewModel(true);
  };
  const viewModelCloseFun = () => setViewModel(false);
  return (
    <>
      {Boolean(mainLoader) ? (
        <Loader />
      ) : Object.keys(helpSupportData)?.length > 0 ? (
        <>
          <Table bordered responsive className="text-center">
            <thead className="tableHeader">
              <tr>
                <th className="number-width border">No</th>
                <th>FirstName</th>
                <th>LastName</th>
                <th className="w-25">Email</th>
                <th>Message</th>
                <th>Created_at</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {helpSupportData.length > 0 ? (
                helpSupportData?.map((item, index) => (
                  <tr key={item?._id}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>{item?.firstName}</td>
                    <td>
                      <div className="wordBreak1 text-center align-middle">
                        {item?.lastName}
                      </div>
                    </td>
                    <td>
                      <div className="wordBreak1 text-center align-middle">
                        {item?.email}
                      </div>
                    </td>
                    <td>
                      <div className="wordBreak1 text-center align-middle">
                        {item.message ? item?.message : "-"}
                      </div>
                    </td>
                    <td>{format(new Date(item?.created_at), 'dd-MM-yyyy')}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <button className="border-0 bg-transparent" onClick={() => viewModelOpenFun(item)}>
                          <img src="/images/svg/show.svg" title="View" alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-md-flex justify-content-end align-items-center">
            {Boolean(helpSupportData.length) && paginationData.totalPages > 1 && (
              <TablePagination
                currentPage={paginationData.currentPage}
                totalPages={paginationData.totalPages}
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
              />
            )}
          </div></>
      ) : (
        <h4 className="text-center p-5">No Records Founds.</h4>
      )}

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Edit Help & Support
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label>Title</label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="title"
                value={editData?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label>Description</label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter home description here"
                type="text"
                name="description"
                value={editData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
            <Col md={12}>
              <label>Contact No</label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="number"
                name="contact"
                value={editData?.contact || ""}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.contact} />
            </Col>
            <Col md={12}>
              <label>Email</label>
              <input
                className="form-control px-3"
                placeholder="Enter email address"
                type="text"
                name="email"
                value={editData?.email || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.email} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                handleSubmit();
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">View Help & Support</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          {selectedItem && (
            <Row>
              <div className="d-flex justify-content-center">
                <div className="w-100">
                  <div className="mb-3">
                    <tr>
                      <td className="userDetailsLabel text-nowrap">First Name :</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.message ? selectedItem.firstName : '-'}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel text-nowrap">Last Name :</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.message ? selectedItem.lastName : '-'}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel text-nowrap">Email :</td>
                      &nbsp;
                      <td className="userDetailsText">{selectedItem.message ? selectedItem.email : '-'}</td>
                    </tr>
                    <tr>
                      <td className="userDetailsLabel text-nowrap">Message :</td>
                      &nbsp;
                      <td className="userDetailsText max-width-100">{selectedItem.message ? selectedItem.message : '-'}</td>
                    </tr>


                    <tr>
                      <td className="userDetailsLabel text-nowrap">Created Date:</td>
                      &nbsp;
                      <td className="userDetailsText">{format(new Date(selectedItem?.created_at), 'dd/MM/yyyy')}</td>
                    </tr>


                  </div>
                </div>
              </div>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HelpAndSupport;
