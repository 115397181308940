import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import WeekList from "../components/WeekList";
import { getServerURL } from "../helper/envConfig";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
import { errorResponse } from "../helper/error";
import { toast } from "react-toastify";
import Main from "../hoc/Main";
import SingleError from "../helper/SingleError";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { debounce } from "lodash";
import CustomUploadAdapter from "../components/adapterclass/CustomUploadAdapter";
import { useSelector } from "react-redux";
import { ValidateFields } from "../components/validate/ValidateFields";
const requireField = [
  "title",
  "description",
  "duration",
  "content",
];

const Week = () => {
  const location = useLocation();
  const { state } = location;
  const serverURL = getServerURL();
  const lecture_id = state?.id;

  const weekData = useSelector((state) => state?.chapter)
  const chapter_index = weekData[weekData?.length - 1]?.chapter_index + 1 || 1
  const [addModel, setAddModel] = useState(false);

  const [states, setStates] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [page] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const addModelOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setStates({ lecture_id, chapter_index });
    setAddModel(true);
  };
  const addModelCloseFun = () => setAddModel(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...states, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    setStates((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const appendObjectToFormData = (obj, formData, prefix = "") => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === "object") {
          appendObjectToFormData(obj[key], formData, propName);
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
  };

  const addWeek = () => {
    setTemp(false);
    const updatedValues = { ...states };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.forEach(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors)?.length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "create");
      for (const key in states) {
        if (states.hasOwnProperty(key)) {
          if (typeof states[key] === "object") {
            appendObjectToFormData(states[key], formData, key);
          } else {
            formData.append(key, states[key]);
          }
        }
      }
      try {
        api
          .postWithToken(
            `${serverURL}chapter-manage?page=${page}&limit=${limit}&search=${search}`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModelCloseFun();
              setTemp(true);
              setStates({});
              if (typeof res?.data?.data?.message === "string")
                toast.success(res?.data?.data?.message);
              else toast.success("Week created successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleEditorChange = debounce((event, editor) => {
    const data = editor.getData();
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...states, content: data });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors["content"];
      }
    }
    setStates({ ...states, content: data });
  }, 300);

  const editorConfiguration = {
    extraPlugins: [CustomAdapterPlugin],
    mediaEmbed: {
      previewsInData: true,
    },

    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        // Ensure "link" is not included here
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "|",
        "imageUpload",
        "|",
        "mediaEmbed",
      ],
    },

    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
      resizeUnit: "px",
    },
  };

  function CustomAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader);
    };
  }

  const disableAutocomplete = (e) => {
    e.target.setAttribute("autocomplete", "off");
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitCount(1);
      addWeek();
    }
  };

  return (
    <>
      <Main name="Week Management">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">          <Col md={6} lg={5} xl={7}>
          <h1 className="title mb-0">
            {state?.lectureData?.title || "Weeks"}
          </h1>
        </Col>
          <Col sm={12} md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3">
              <div className="form-control d-flex align-items-center px-3">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="form-control border-0 shadow-none pl-black"
                  placeholder="Enter Search"
                  autoComplete="off"
                  type="text"
                  name="search"
                  onFocus={disableAutocomplete}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setSearchTemp(true);
                  }}
                />
              </div>
              <div
                onClick={() => {
                  addModelOpenFun();
                }}
                className="cursor-pointer"
              >
                <img
                  src="/images/svg/add.svg"
                  className="add-btn"
                  alt=""
                  title="Add"
                />
              </div>
            </div>
          </Col>
        </div>
        <WeekList
          temp={temp}
          search={search}
          lectureData={state}
          searchTemp={searchTemp}
          lecturePage={state?.page}
        />
      </Main>

      {/* Add modal */}
      <Modal
        size="lg"
        show={addModel}
        onHide={addModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Week</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter week title here"
                type="text"
                name="title"
                value={states?.title || ""}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
              />
              <SingleError error={errors?.title} />
            </Col>

            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                className="form-control px-3"
                placeholder="Enter week description here"
                type="text"
                row={3}
                name="description"
                value={states?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

            <Col md={12}>
              <label id="duration">
                Time <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter week time duration here (e.g., 10 min)"
                type="text"
                name="duration"
                value={states?.duration || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.duration} />
            </Col>

            <Col md={12}>
              <label id="content">
                Content <span className="star">*</span>
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={states?.content || ""}
                onChange={handleEditorChange}
                config={editorConfiguration}
              />
              <SingleError error={errors?.content} mb={false} />
            </Col>

          </Row>
          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                addWeek();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Week;
