import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { getImageURL, getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";
import { ValidateFields } from "../validate/ValidateFields";
import ErrorFilter from "../../helper/errorFilter";
import Loader from "../loader/Loader";
import SingleError from "../../helper/SingleError";
const requireField = [
  "best_for_you_image",
  "description",
];

const BestForYou = ({ temp, search, searchTemp, setCountForYou }) => {

  const serverURL = getServerURL();
  const imageURL = getImageURL();
  const fileInputRef = useRef(null);

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);

  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const [loading, setLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);

  const [submitCount, setSubmitCount] = useState(0)
  const [errors, setErrors] = useState({})
  const [mainLoader, setMainLoader] = useState(true);

  const [forYouData, setForYouData] = useState()
  const [editData, setEditData] = useState({})
  const [page, setPage] = useState(1);


  useEffect(() => {
    getForYouList();
  }, []);

  useEffect(() => {
    getForYouList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getForYouList();

    }
    if (searchTemp && !search) {
      getForYouList();

    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const getForYouList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}landing-page-manage`,
          { action: "for_you_get" }
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            if (Boolean(res?.data && Object.keys(res?.data?.data)?.length)) setCountForYou(1)
            setForYouData(res?.data?.data);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setForYouData([]);
            if (Boolean(page !== 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) {
      setMainLoader(false);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editData, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    setEditData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditData({
        ...editData,
        [e.target.name]: e.target.files[0],
      });
    }
  };



  const handleSubmit = () => {
    const updatedValues = { ...editData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.forEach(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors)?.length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", editData?._id);
      formData.append("action", "for_you_update");
      // formData.append("title", editData?.title);
      formData.append("description", editData?.description);
      formData.append("best_for_you_image", editData?.best_for_you_image);

      try {
        api
          .postWithToken(
            `${serverURL}landing-page-manage`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              editModelCloseFun();
              getForYouList()
              setEditData({})
              if (typeof res?.data?.data?.message === "string")
                toast?.success(res?.data?.data?.message);
              else toast.success("Best For You data updated successfully");
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {Boolean(mainLoader) ?
        <Loader /> :
        Boolean(Object.keys(forYouData)?.length) ?
          <div className="card p-3 mt-3">
            <div className="d-flex justify-content-between gap-3">
              <img src={imageURL + forYouData?.best_for_you_image} className="for-you-img" alt="" />
              <div className=" ">
                <div className="explore-head col-10">
                  {/* {forYouData?.title} */}
                  Best For You
                </div>
                <div className="explore-description col-12">
                  {forYouData?.description}

                </div>
              </div>
              <div className="d-flex justify-content-center gap-1 align-items-start">
                {/* <button
                  className="border-0 bg-transparent"
                  onClick={()=>{setEditData(forYouData);
                    viewModelOpenFun()}}
                >
                  <img src="/images/svg/show.svg" title="Edit" />
                </button> */}
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    setEditData(forYouData);
                    editModelOpenFun()
                  }}
                >
                  <img src="/images/svg/edit.svg" title="Edit" alt="" />
                </button>
              </div>
            </div>
          </div>
          :
          <h4 className="text-center p-5">No Records Founds.</h4>
      }

      {/* View modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">For You Details</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0cmt-3">
          <Row>
            <div className="position-relative">
              <div className="d-flex justify-content-center m-auto edit-user">
                <img src={editData?.best_for_you_image && imageURL + editData?.best_for_you_image} className="cursor-pointer" alt="" />
              </div>
            </div>
          </Row>
          <Row className="px-5 pb-3">
            <div className="d-flex justify-content-center">
              <div>
                {/* <tr>
                  <td className="userDetailsLabel">Title:</td>
                  &nbsp;
                  <td className="userDetailsText">{editData?.title || "-"}</td>
                </tr> */}

                <tr>
                  <td className="userDetailsLabel">Description:</td>
                  &nbsp;
                  <td className="userDetailsText fullTextShow">
                    {editData?.description || "-"}
                  </td>
                </tr>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit For You</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="best_for_you_image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    editData?.best_for_you_image
                      ? typeof editData?.best_for_you_image === "string"
                        ? imageURL + editData?.best_for_you_image
                        : editData?.best_for_you_image?.type
                          ? URL.createObjectURL(editData?.best_for_you_image)
                          : "/images/png/duser.png"
                      : selectedImage
                        ? selectedImage
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : editData?.best_for_you_image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                  alt="" />
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="best_for_you_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.review_image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.review_image} />
              </div>
            )}
          </Row>
          <Row>
            {/* <Col md={12} id="title">
              <label>Title <span className="star">*</span></label>
              <input
                className="form-control px-3"
                placeholder="Enter explore title here"
                type="text"
                name="title"
                value={editData?.title || ""}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
              />
              <SingleError error={errors?.title} />
            </Col> */}
            <Col md={12} id="description">
              <label>Description</label>
              <textarea
                rows={5}
                className="form-control px-3"
                placeholder="Enter explore description here"
                type="text"
                name="description"
                value={editData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>

          </Row>
          <div className="d-flex justify-content-center ">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
              ${Boolean(loading) && "btnDisable"}`}
              disabled={Boolean(loading)}
              onClick={() => {
                handleSubmit();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default BestForYou;
