import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { errorResponse } from "../helper/error";
import { toast } from "react-toastify";
import { getServerURL } from "../helper/envConfig";
import { Link } from "react-router-dom";
import api from "../helper/api";
import SingleError from "../helper/SingleError";
const requireField = ["videoUrl"];

const VideoLinkTable = ({ temp }) => {

  const serverURL = getServerURL();

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setSelectedImage(null);
    setErrors({});
    setSubmitCount(0)
    setEditModel(true)
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [deleteVideoID, setDeleteVideoID] = useState("");

  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [videoList, setVideoList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editVideoLink, setEditVideoLink] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true)

  // useEffect(() => {
  //   getVideoLinkList();
  // }, []);

  useEffect(() => {
    getVideoLinkList();
  }, [page]);

  useEffect(() => {
    if (temp) {
      getVideoLinkList();
    }
  }, [temp]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editVideoLink,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditVideoLink((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getVideoLinkList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}video-playlist-manage?page=${page}&limit=${limit}`,
          { action: "list" }
        )
        .then(async (res) => {
          // console.log(res);
          setMainLoader(false)
          if (res?.data?.success === true) {
            setVideoList(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          setMainLoader(false)
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setVideoList([])
          }
          // errorResponse(e);
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const deleteVideoLinkList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}video-playlist-manage`,
          { action: "delete", video_id: deleteVideoID }
        )
        .then(async (res) => {
          // console.log(res);
          setDeleteVideoID("");
          getVideoLinkList();
          if (videoList?.length == 1) {
            setVideoList([])
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Lecture deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  const editVideoLinkList = () => {
    const updatedValues = { ...editVideoLink };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0) document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        api
          .postWithToken(
            `${serverURL}video-playlist-manage`,
            { action: "update", videoUrl: editVideoLink?.videoUrl || "", video_id: editVideoLink?._id }
          )
          .then(async (res) => {
            setLoading(false);
            // console.log(res);
            if (res?.data?.success === true) {
              getVideoLinkList();
              editModelCloseFun();
              setSelectedImage("");
              if (res?.data?.success === true) {
                if (typeof res?.data?.message === "string")
                  toast.success(res?.data?.message);
                else toast?.success("Quote Updated successfully");
              }
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  return (
    <>
      <Col className="ctmTable">
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th className="number-width border">No</th>
              <th>Video Link</th>
              {/* <th>Date</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody ">
            {videoList?.map((item, index) => (
              <tr key={item?.id}>
                <td>{(page - 1) * limit + index + 1}</td>
                <td className="w-50 fullTextShow text-start px-5">{item?.videoUrl}</td>
                {/* <td>22-02-2024</td> */}

                <td>
                  <div className="d-flex justify-content-center align-items-center gap-1">
                    <Link to={item?.videoUrl} target="_blank" rel="noopener noreferrer">
                      <button
                        className="border-0 bg-transparent"
                      // onClick={viewModelOpenFun}
                      >
                        <img src="/images/svg/show.svg" title="View" alt="view" />
                      </button>
                    </Link>
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        editModelOpenFun();
                        setEditVideoLink(item);
                      }}
                    >
                      <img src="/images/svg/edit.svg" title="Edit" alt="edit" />
                    </button>
                    <button
                      className="border-0 bg-transparent"
                      onClick={(e) => {
                        deleteModelOpenFun(item?._id);
                        setDeleteVideoID(item?._id);
                      }}
                    >
                      <img src="/images/svg/delete.svg" title="Delete" alt="delete" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-md-flex justify-content-end align-items-center">
          {Boolean(videoList?.length) && paginationData?.totalPages > 1 && (
            <TablePagination
              currentPage={paginationData?.currentPage}
              totalPages={paginationData?.totalPages}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </div>
      </Col>

      {/* View user modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal pl-0"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">View Video Link</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="s-img" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="justify-content-center">
            <Col className="col-11 px-0" >
              <div className="d-flex justify-content-center">
                <div className="my-4">
                  <tr>
                    <td className="userDetailsLabel">VideoLink :</td>
                    &nbsp;
                    <td className="userDetailsText fullTextShow">{editVideoLink?.videoUrl || "-"}</td>
                  </tr>
                </div>
              </div>
            </Col>

          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Video Link</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label id="videoUrl">Video Link <span className="star">*</span></label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Please enter video link here"
                type="text"
                name="videoUrl"
                value={editVideoLink?.videoUrl || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.videoUrl} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4"
              onClick={() => { editVideoLinkList() }}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Video Link</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            {/* <button
              className="modalCancelBtn px-5 border-0"
              onClick={() => deleteModelCloseFun}
            >
              Cancel
            </button> */}
            <button className="modalDeleteBtn border-0" onClick={() => deleteVideoLinkList()}>Delete</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VideoLinkTable;
