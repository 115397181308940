import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { errorResponse } from "../helper/error";
import { toast } from "react-toastify";
import { getServerURL } from "../helper/envConfig";
import api from "../helper/api";
import Loader from "./loader/Loader";
const requireField = ["title", "date", "description"];

const QuoteTable = ({ temp, search, searchTemp }) => {
  const serverURL = getServerURL();

  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const viewModelOpenFun = () => setViewModel(true);
  const viewModelCloseFun = () => setViewModel(false);

  const editModelOpenFun = () => {
    setSelectedImage(null);
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [deleteDataID, setDeleteDataID] = useState("");

  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [quoteList, setQuoteList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editQuoteData, setEditQuoteData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);




  const handleChange = (e) => {
    const { name, value } = e.target;

    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editQuoteData,
        [name]: value,
      });

      // Additional date validation
      if (name === "date") {
        const today = new Date().toISOString().slice(0, 16); // Current date in yyyy-MM-ddTHH:mm format

        if (!value) {
          validationErrors.date = "Date is required.";
        } else if (value < today) {
          validationErrors.date = "The date and time must be today or later.";
        } else {
          delete validationErrors.date; // Remove the error if the date is valid
        }
      }

      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
    }

    // Update the state with the new value
    setEditQuoteData((prevValues) => ({
      ...prevValues,
      [name]: value, // Keep the original value from the input
    }));
  };
  const getQuoteList =useCallback( async () => {
    try {
      const res = await api.postWithToken(
        `${serverURL}quote-manage?page=${page}&limit=${limit}&search=${search}`,
        { action: "list" }
      );
  
      setMainLoader(false);
  
      if (res?.data?.success) {
        const formattedData = res.data.data.map(item => ({
          ...item,
          date: new Date(item.date).toISOString().slice(0, 16), // Convert to yyyy-MM-ddTHH:mm
        }));
        setQuoteList(formattedData);
        setPaginationData(res.data.pagination || {});
      } else {
        setQuoteList([]);
      }
    } catch (e) {
      setMainLoader(false);

      if (e?.response?.status === 404) {
        setQuoteList([]);
      } else {
        errorResponse(e);
      }
    }
  }, [page, limit, search, serverURL])
  useEffect(() => {
    getQuoteList();
  }, [getQuoteList]);
  useEffect(() => {
    getQuoteList();
  }, [page, getQuoteList]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getQuoteList();
    }
    if (searchTemp && !search) {
      getQuoteList();
    }
  }, [temp, search,searchTemp, getQuoteList]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);



  const deleteQuote = () => {
    setLoading(true); // Optional: Set a loading state to indicate that a request is in progress

    // Append the quote_id as a query parameter to the URL
    const url = `${serverURL}quote-manage?id=${deleteDataID}`;

    api
      .postWithToken(url, { action: "delete" }) // Send action as part of the body if needed
      .then((res) => {
        setLoading(false); // Reset the loading state
        if (res?.data?.success) {
          toast.success("Quote deleted successfully");
          setDeleteDataID(""); // Clear the delete ID
          getQuoteList(); // Refresh the list of quotes
          if (quoteList.length === 1 && paginationData.currentPage > 1) {
            setPage((prePage) => prePage - 1);
          } else {
            getQuoteList();
          }
          deleteModelCloseFun(); // Close the delete modal
        } else {
          toast.error(res?.data?.message || "Failed to delete the quote");
        }
      })
      .catch((e) => {
        setLoading(false); // Reset the loading state
        errorResponse(e); // Handle the error appropriately
      });
  };

  const editQuote = () => {
    // Trim whitespace from all string fields in editQuoteData
    const updatedValues = {
      ...editQuoteData,
      title: editQuoteData?.title?.trim() || "",
      description: editQuoteData?.description?.trim() || "",
    };

    let validationErrors = ValidateFields(updatedValues);

    // Convert input date to local time for comparison
    const now = new Date();
    const selectedDate = new Date(updatedValues.date);

    if (!selectedDate) {
      validationErrors.date = "Date and time are required.";
    } else if (selectedDate.getTime() < now.getTime()) {
      validationErrors.date = "The date and time must be today or later.";
    }

    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);

      const payload = {
        quote_id: updatedValues._id || "",
        action: "update",
        title: updatedValues.title, // Use trimmed title
        date: updatedValues.date, // Use the raw date value
        description: updatedValues.description, // Use trimmed description
      };

      api
        .postWithToken(`${serverURL}quote-manage`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoading(false);
          if (res?.data?.success) {
            getQuoteList();
            editModelCloseFun();
            setSelectedImage(null);
            toast.success(res?.data?.message || "Quote updated successfully");
          } else {
            toast.error(res?.data?.message || "Failed to update the quote");
          }
        })
        .catch((e) => {
          setLoading(false);
          errorResponse(e);
        });
    }
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC' // Ensure we treat the input as UTC
    };
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', options).replace(',', ''); // Format to `DD-MM-YYYY HH:MM AM/PM`
  };

  return (
    <>
      {mainLoader ? (
        <div className="loading-indicator">
          <Loader />
        </div>
      ) : (
        <Col className="ctmTable">
          <Table bordered responsive className="text-center">
            <thead className="tableHeader">
              <tr>
                <th className="number-width border">No</th>
                <th>Title</th>
                <th>Description</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {quoteList.length > 0 ? (
                quoteList.map((quote, index) => (
                  <tr key={quote._id}>
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>{quote.title}</td>
                    <td>{quote.description}</td>
                    <td>{formatDate(quote.date)}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center gap-1">
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => {
                            setEditQuoteData(quote);
                            viewModelOpenFun();
                          }}
                        >
                          <img src="/images/svg/show.svg" title="View" alt="" />
                        </button>
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => {
                            setEditQuoteData(quote);
                            editModelOpenFun();
                          }}
                        >
                          <img src="/images/svg/edit.svg" title="Edit" alt="" />
                        </button>
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => {
                            setDeleteDataID(quote._id);
                            deleteModelOpenFun();
                          }}
                        >
                          <img src="/images/svg/delete.svg" title="Delete" alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No quotes available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-md-flex justify-content-end align-items-center">
            {Boolean(quoteList?.length) && paginationData?.totalPages > 1 && (
              <TablePagination
                currentPage={paginationData?.currentPage}
                totalPages={paginationData?.totalPages}
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
              />
            )}
          </div>
        </Col>
      )}

      {/* View user modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Quote Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            {/* <div className="d-flex justify-content-center">
              <div>
                <div className="mb-3">
                  <tr>
                    <td className="userDetailsLabel">Title:</td>
                    &nbsp;
                    <td className="userDetailsText">{editQuoteData?.title}</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Date:</td>
                    &nbsp;
                    <td className="userDetailsText">
                      {editQuoteData?.date ? formatDateTime(editQuoteData.date) : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel">Description:</td>
                    &nbsp;
                    <td className="userDetailsText">
                    <pre>
                      {editQuoteData?.description || ""}
                    </pre>
                    </td>
                  </tr>
                </div>
              </div>
            </div> */}
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <div className="mb-3">
                  <tr>
                    <td className="userDetailsLabel text-nowrap">Title :</td>
                    &nbsp;
                    <td className="userDetailsText">{editQuoteData?.title}</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel text-nowrap">Date:</td>
                    &nbsp;
                    <td className="userDetailsText"> {editQuoteData?.date ? formatDateTime(editQuoteData.date) : ""}</td>
                  </tr>
                  <tr>
                    <td className="userDetailsLabel text-nowrap">Description:</td>
                    &nbsp;
                    <td className="userDetailsText max-width-100"> {editQuoteData?.description || ""}</td>
                  </tr>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter quote title here"
                    value={editQuoteData.title || ""}
                    onChange={handleChange}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Date and Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="date"
                    value={editQuoteData?.date || ""} // Use formatted date directly
                    onChange={handleChange}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>

              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    placeholder="Enter quote description here"
                    value={editQuoteData.description || ""}
                    onChange={handleChange}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${loading ? "btnDisable" : ""
                }`}
              disabled={loading}
              onClick={editQuote}
            >
              {loading ? "Loading..." : "Edit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}

      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Quote </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button className="modalDeleteBtn border-0" onClick={deleteQuote}>
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuoteTable;
