import React, { useState } from "react";
import SubscriptionTable from "../components/SubscriptionTable";
import Main from "../hoc/Main";
import Loader from "../components/loader/Loader";

export const SubscriptionManagement = () => {

  const [mainLoader, setMainLoader] = useState(true);

  return (
    <>
      {mainLoader && (
        <div className="loading-indicator">
          <Loader />
        </div>
      )}
      <Main name="Subscription Management">
        <SubscriptionTable setMainLoader={setMainLoader} />
      </Main>
    </>
  );
};
